<template>
  <div class="section section-tabs">
    <div class="container">
      <div class="title">
        <h4>Serviços</h4>
      </div>
      <div class="row">
        <div class="col-md-10 ml-auto col-xl-12 mr-auto">
          <p class="category">Gestão & Assessoria</p>
          <!-- Nav tabs -->
          <card>
            <tabs
              slot="raw-content"
              tab-content-classes="tab-content-padding text-center"
            >
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons objects_umbrella-13"></i> Folha de Pagamento
                </template>
                <p class="text-left">
                  A assessoria em folha de pagamento aperfeiçoa a gestão da empresa. No segmento trabalhista, uma das áreas mais
                  burocráticas e complexas é a folha de pagamento. Sendo assim, a Tironi Assessoria atua com o suporte essencial de profissionais
                  qualificados com anos de mercado, para que a gestão nos processos de departamento pessoal e recursos humanos seja devidamente simplificada.
                </p>
                <h4 class="text-left">Por que contratar</h4>
                <p class="text-left">
                   Ao terceirizar os serviços assessórios, você libera esforços e tempo para focar na atividade fim do negócio principal da sua empresa.
                   A <b>Tironi Assessoria Empresarial</b> é uma empresa que possui amplo conhecimento e experiência em assessoria em folha de pagamento. A empresa atua em total 
                   conformidade com as leis vigentes. 
                </p>
                <h4 class="text-left">Resultados</h4>
                <p class="text-left">
                  <ol class="col-xl-12">
                    <li>Além da redução nos custos com empregados, treinados e especializados para atividades assessorias, terá menor risco no cumprimento das exigências legais.</li>
                    <li>Tenha a sua disponibilidade, profissionais capacitados para tirar dúvidas e resolver situações cotidianas relacionadas à folha de pagamento.</li>
                    <li>Realizamos o lançamento mensal de folha de pagamento da sua empresa.</li>
                  </ol>
                </p>
              </tab-pane>
              <!-- <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons shopping_cart-simple"></i> Contabilidade
                </template>
                <h4 class="text-left">Por que contratar</h4>
                <p class="text-left">
                  
                </p>
                <h4 class="text-left">Resultados</h4>
              </tab-pane>               -->
            </tabs>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 ml-auto col-xl-12 mr-auto mt-4">
          <p class="category">Consultorias</p>
          <div class="card">
            <tabs
              centered
              type="neutral"
              tab-nav-wrapper-classes="card-header"
              tab-content-classes="card-body text-center"
              data-background-color="orange"
            >
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons objects_umbrella-13"></i> Melhoria de Processos Produtivos <badge type="success">Novo</badge>
                </template>
                <p class="text-left">
                  A Consultoria de Melhoria de Processos Produtivos é um upgrade completo do processo produtivo de sua empresa. Sob a ótica da
                  renomada prática de produção Toyota, aplicada no mundo todo, seguindo todos os padrões, visamos alcançar a melhor 
                  economia e aumento de produtividade.                  
                </p>
                <p class="text-left"> As etapas da consultoria são: </p>
                <p class="text-left">
                  <ol class="col-xl-12">
                    <li> Avaliação do processo produtivo (Gemba) </li>
                    <li> Definição de KPIs </li>                    
                    <li> Identificação e eliminação de desperdícios </li>
                    <li> Reorganização de estoque </li>
                    <li> Padronização de Processos </li>
                    <li> Melhoria de fluxo logístico </li>
                    <li> Aplicação de workshops seguindo modelo Kaizen e PDCA </li>                    
                  </ol>
                </p>
                <h3 class="text-left">Por que contratar</h3>
                <p class="text-left">
                  Esta consultoria é para empresas que buscam crescimento e enfrentam barreiras para melhorar e aumentar a produtividade e reduzir custos.
                </p>
                <h3 class="text-left">Resultados</h3>
                <p class="text-left">Forneça melhor atendimento aos clientes, com agilidade na manufatura de seus produtos.</p>
              </tab-pane>
              
            </tabs>
          </div>
        </div>     
      </div>
    </div>
  </div>
</template>
<script>
import { Badge, Card, Tabs, TabPane } from '@/components';

export default {
  name: "tabs-component",
  components: {
    Badge,
    Card,
    Tabs,
    TabPane
  }
};
</script>
<style>
.tab-content.tab-content-padding {
  padding: 20px;
}
</style>

<template>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Nossa história</h2>
            <p>
                Tironi Assessoria Empresarial, nasceu em janeiro de 2010, 
                com a necessidade do seu idealizador - Jusmar Tironi - 
                de reinventar para uma nova atividade, pois estava 
                encerrando o ciclo como empregado – gestor de recursos 
                humanos - em uma grande organização empresarial.  
                Além de ser administrador de empresa, tinha concluído o 
                curso superior de tecnólogo 
                em processamento de dados, e tinha a intenção de aplicar 
                esse conhecimento tecnológico, aliado ao vasto 
                conhecimento da legislação trabalhista para assessorar 
                os pequenos empreendedores na organização e administração 
                dos seu quadro de pessoal.                 
            </p>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <!-- <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/login.jpg')"
              >
                <p class="blockquote blockquote-primary">
                  "Over the span of the satellite record, Arctic sea ice has
                  been declining significantly, while sea ice in the
                  Antarctichas increased very slightly"
                  <br />
                  <br />
                  <small>-NOAA</small>
                </p>
              </div>              
              <div
                class="image-container"
                style="background-image: url('img/bg3.jpg')"
              ></div>
            </div> -->
            <div class="col-md-8 ml-auto mr-auto text-center">
              <!-- First image on the right side, above the article -->
              <!-- <div
                class="image-container image-right"
                style="background-image: url('img/bg1.jpg')"
              ></div> -->
              <h3>
                Motivação e mercado
              </h3>
              <p>
                Vendo que esses pequenos empreendedores, 
                por falta de conhecimento e recursos, 
                não cumpriam integralmente a legislação trabalhista e 
                tinham grande dificuldade para gerir as relações trabalhista 
                nestes pequenos empreendimentos, gerando passivos 
                trabalhistas, passou a atendê-los, inicialmente na orientação 
                e depois com o apoio necessários para controles, 
                registros de empregados, processamento da folha de 
                pagamentos, encargos, tributos, enquadramento sindical e 
                até mesmo acompanhamento para soluções de conflitos na área 
                trabalhista.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: "history-component"
}

</script>

<style>


</style>
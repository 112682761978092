import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/scss/kit.scss';
import '@/assets/site/site.css';
import globalMixins from './globalMixins';
import globalComponents from './globalComponents';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
Vue.use(globalComponents);
Vue.use(globalMixins);

export default new Vuetify({
});

import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import MainNavbar from '../layout/MainNavbar.vue';
import MainFooter from '../layout/MainFooter.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index-page',
    components: { default: Index, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

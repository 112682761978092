<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('https://bucket-tironiassessoria-public.s3.sa-east-1.amazonaws.com/header.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <img class="n-logo" src="https://bucket-tironiassessoria-public.s3.sa-east-1.amazonaws.com/tironi-assessoria-white.png" alt="" />
          <h1 class="h1-seo">Tironi Assessoria.</h1>
          <h3>Folha de pagamento, Contabilidade & Consultorias</h3>
        </div>
        <h6 class="category category-absolute">
          Desde 2010
        </h6>
      </div>
    </div>
    <tabs-section></tabs-section>
    <history-section></history-section>
  </div>  
</template>

<script>
  import { Parallax } from '@/components';
  import TabsSection from './components/Tabs';
  import HistorySection from './components/History';

  export default {
    name: 'index-page',
    bodyClass: 'index-page',

    components: {
      HistorySection,
      TabsSection,
      Parallax
    },
  }
</script>
